import { Answer } from 'routes/pages/Report/pages/Questions/types';

const STORAGE_KEY = 'answers';

export function saveAnswerToLocalStorage(newAnswers: Answer[]): void {
  const answers = getAnswersFromLocalStorage();

  newAnswers.forEach((answer) => {
    const existingAnswerIndex = answers.findIndex((a) => a.questionId === answer.questionId);

    existingAnswerIndex !== -1 ? (answers[existingAnswerIndex] = answer) : answers.push(answer);
  });

  const serializedAnswers = JSON.stringify(answers);
  localStorage.setItem(STORAGE_KEY, serializedAnswers);
}

export function getAnswersFromLocalStorage(): Answer[] {
  const serializedQuestionnaire = localStorage.getItem(STORAGE_KEY);
  if (serializedQuestionnaire) {
    return JSON.parse(serializedQuestionnaire);
  }

  return [];
}
