import React from 'react';

type GridTableRowProps = {
  children: React.ReactNode;
};

export const GridTableRow = (props: GridTableRowProps) => {
  const { children } = props;

  return <tr>{children}</tr>;
};
