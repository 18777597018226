import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { formatNumber } from '@simplifiers/formatters/numbers.ts';
import { removeExtraCharactersFromCurrencyInput, withDotSeparator } from './formatters';

export const CurrencyInput = React.forwardRef(
  (
    { name, control, ...props }: UseControllerProps<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { field } = useController({ name, control });

    return (
      <input
        type="text"
        inputMode="decimal"
        pattern="[0-9.,]*"
        {...props}
        {...field}
        onChange={(e) => field.onChange(removeExtraCharactersFromCurrencyInput(e.target.value))}
        ref={ref}
        onBlur={(e) => field.onChange(formatNumber(withDotSeparator(e.target.value)))}
      />
    );
  }
);
