import { formatNumber } from '@simplifiers/formatters/numbers';
import { withDotSeparator } from './formatters';

export const getSum = (data, key: string) => {
  const sum = data.reduce((sum: number, item: number) => {
    const value = withDotSeparator(item[key]);
    return sum + (parseFloat(value) || 0);
  }, 0);

  const result = Math.round(sum * 100) / 100;
  return formatNumber(result);
};

export const getDifference = (debit: string, credit: string) => {
  const debitValue = withDotSeparator(debit);
  const creditValue = withDotSeparator(credit);

  const difference = parseFloat(debitValue) - parseFloat(creditValue);
  const result = Math.round(difference * 100) / 100;

  return formatNumber(result);
};
