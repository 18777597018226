import { InlineContent } from '@simplifiers/ui/Layout/InlineContent';
import { Title } from '@simplifiers/ui/Typography';
import { DefinitionCard } from './DefinitionCard';
import styles from './MultiYearDefinition.module.css';

export const MultiYearDefinition = () => {
  return (
    <>
      <Title className={styles.title}>Nyckeltalsdefinitioner</Title>
      <div className={styles.container}>
        <dl>
          <InlineContent>
            <DefinitionCard
              className={styles.card}
              term="Nettoomsättning:"
              definition="Huvudintäkter, fakturerade kostnader, sidointäkter samt intäktskorrigeringar."
            />
            <DefinitionCard
              className={styles.card}
              term="Resultat efter finansiella poster:"
              definition="Resultat före bokslutsdispositioner och skatt."
            />
            <DefinitionCard
              className={styles.card}
              term="Soliditet:"
              definition="Justerat eget kapital i procent av balansomslutning."
            />
          </InlineContent>
        </dl>
      </div>
    </>
  );
};
