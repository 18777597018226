import { Text, Title } from '@simplifiers/ui/Typography';
import styles from './ManagementReport.module.css';
import { CaptionField } from './components/CaptionField';

type AboutCompanyProps = {
  companyActivity: string;
  registeredOffice: string;
  significantEvents?: string;
};

const mockTextEvents =
  '(I frågan ger vi exempel på vad det kan vara, det handlar om att förklara händelser som förklarar varför siffrorna är som de är.) Är svaret Nej, så genererar det följande text i förvaltningsberättelsen: “Inga väsentliga händelser har inträffat under verksamhetsåret”';

export const AboutCompany = (props: AboutCompanyProps) => {
  const { companyActivity, registeredOffice, significantEvents = mockTextEvents } = props;
  const bvLink = 'https://bolagsverket.se';
  const significantEventsTooltip =
    'Här anges händelser av väsentlig betydelse för företaget som har inträffat under året: viktiga förändringar i verksamheten, externa faktorer som påverkar.';

  return (
    <>
      <Title className={styles.spacer}>Om verksamheten</Title>
      <Text>
        Eventuella ändringar görs på{' '}
        <a
          href={bvLink}
          className={styles.link}
          aria-label="Bolagsverket website"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bolagsverket
        </a>{' '}
        eftersom informationen är hämtad därifrån.
      </Text>

      <div className={styles.gridContainer}>
        <CaptionField
          title="Företagets verksamhet"
          fieldName="companyActivity"
          fieldValue={companyActivity}
          multiline
        />
        <CaptionField title="Bolagets säte" fieldName="registeredOffice" fieldValue={registeredOffice} />
        <CaptionField
          title="Väsentliga händelser under verksamhetsåret"
          fieldName="significantEvents"
          fieldValue={significantEvents}
          tooltip={significantEventsTooltip}
          multiline
        />
      </div>
    </>
  );
};
