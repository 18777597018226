import { QueryClient } from '@tanstack/react-query';
import { annualReportLoader } from '../../loader';
import { BalanceSheet } from './BalanceSheet';

export const balanceSheetRoutes = (queryClient: QueryClient) => {
  return {
    path: 'balance-sheet',
    element: <BalanceSheet />,
    loader: async () => annualReportLoader(queryClient),
  };
};
