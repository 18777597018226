import { Navigate, RouteObject } from 'react-router-dom';
import { Signing } from './Signing';
import { annualMeetingSigningRoutes } from './pages/AnnualMeetingSigning/route';
import { annualReportSigningRoutes } from './pages/AnnualReportSigning/route';

export const signingRoutes: RouteObject = {
  path: 'signing',
  element: <Signing />,
  children: [
    { index: true, element: <Navigate to="annual-report-signing" replace /> },
    { path: '*', element: <Navigate to="annual-report-signing" replace /> },
    annualReportSigningRoutes,
    annualMeetingSigningRoutes,
  ],
};
