import { Text, Title } from '@simplifiers/ui/Typography';
import {
  disposableFundsRows,
  profitDistributionProposalRows,
  proposedFundAllocationRows,
} from '../../../resultatDispositionExample';
import styles from '../ManagementReport.module.css';
import { ResultsDispositionTable } from './ResultsDispositionTable';

export const ResultsDisposition = () => {
  return (
    <div className={styles.spacerXL}>
      <Title className={styles.spacer}>Resultatdisposition</Title>

      <Text>Till årsstämmans förfogande står följande medel:</Text>

      <ResultsDispositionTable rows={disposableFundsRows} />

      <div className={styles.spacerXL}>
        <ResultsDispositionTable
          title="Styrelsen föreslår att medlen disponeras enligt följande"
          rows={proposedFundAllocationRows}
        />
      </div>

      <div className={styles.spacerXL}>
        <ResultsDispositionTable title="Förslag till vinstdisposition" rows={profitDistributionProposalRows} />
      </div>
    </div>
  );
};
