import { client } from 'api/config';
import { basicCompanyInformationQuery } from 'routes/pages/Start/pages/BasicInformation/loader';
import { OrganizationBasicInfo } from 'routes/pages/Start/pages/BasicInformation/types';
import { Alert } from '@simplifiers/ui/Alert';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Text, Title } from '@simplifiers/ui/Typography';
import { When } from '@simplifiers/ui/When';
import { useQuery } from '@tanstack/react-query';
import styles from './AnnualReportSigning.module.css';

export const AnnualReportSigning = () => {
  const { data, isLoading } = useQuery<OrganizationBasicInfo | null>(basicCompanyInformationQuery(client.id));

  const representatives = data?.representatives;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!representatives) {
    return null;
  }

  const hasEmptyContactInfo = representatives.some((rep) => !rep.phoneNumber && !rep.email);

  return (
    <Section>
      <Title large>Skicka årsredovisningen för signering</Title>
      <div className={styles.subTitleContainer}>
        <Text>
          Årsredovisningen är klar! Kontrollera först listan över undertecknare innan du skickar iväg årsredovisningen
          för signering. Det är originalet som senare skickas till Bolagsverket.
        </Text>

        <When isTrue={hasEmptyContactInfo}>
          <div className={styles.spacer} />
          <Alert type="warning">
            <Text>
              Det måste finnas antingen mobilnummer eller e-post till undertecknarna. Vänligen lägg till det genom att
              klicka redigeringsikonen till längst ut i listan.
            </Text>
          </Alert>
        </When>
      </div>
    </Section>
  );
};
