import React from 'react';
import cx from 'classnames';
import styles from './GridTable.module.css';

type GridTableCellProps = {
  children: React.ReactNode;
  align?: 'left' | 'right' | 'center';
};

export const GridTableCell = (props: GridTableCellProps) => {
  const { children, align = 'left' } = props;

  return <td className={cx({ [styles.alignRight]: align === 'right' })}>{children}</td>;
};
