export interface ResultsDispositionCell {
  id: string;
  dataType: 'string' | 'number';
  value: string | number;
}

export interface ResultsDispositionRow {
  id: string;
  cells: ResultsDispositionCell[];
}

// TODO: We will replace these mock rows with a function in the Document loader
// to fetch the data from the QB and create the rows.
// Note that the following objects fail in Sonar check as they repeat the same code.
const disposableFundsRows: ResultsDispositionRow[] = [
  {
    id: 'balancedResult',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Balanserat resultat',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '0',
      },
    ],
  },
  {
    id: 'currentYearResult',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Årets resultat',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
  {
    id: 'sum',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Summa',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
];

const proposedFundAllocationRows: ResultsDispositionRow[] = [
  {
    id: 'dividend',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Utdelning',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '45 000,50',
      },
    ],
  },
  {
    id: 'balancedResult',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Balanseras i ny räkning',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
  {
    id: 'sum',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Summa',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
];

const profitDistributionProposalRows: ResultsDispositionRow[] = [
  {
    id: 'shareholdersDividend',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Utdelning till aktieägare (kr per aktie)',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '19 555,00',
      },
    ],
  },
  {
    id: 'dividend',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Utdelning',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
  {
    id: 'shares',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'Antal aktier',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
  {
    id: 'total',
    cells: [
      {
        id: 'label',
        dataType: 'string',
        value: 'I ny räkning överföres',
      },
      {
        id: 'value',
        dataType: 'number',
        value: '776 994,00',
      },
    ],
  },
];

export { disposableFundsRows, proposedFundAllocationRows, profitDistributionProposalRows };
