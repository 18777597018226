import { ReportColumn } from '../../../../types';
import styles from './AnnualReportTable.module.css';

type TableHeaderContentProps = {
  column: ReportColumn;
};

export const TableHeaderContent = ({ column }: TableHeaderContentProps) => {
  if (column.id === 'year') {
    const years = column.value.toString().split(' ');
    return (
      <div className={styles.years}>
        {years.map((y) => (
          <span key={y}>{y}</span>
        ))}
      </div>
    );
  }
  return <>{column.value}</>;
};
