import { QueryClient } from '@tanstack/react-query';
import { Report } from './Report';
import { annualReportRoutes } from './pages/AnnualReport/route';
import { questionsRoutes } from './pages/Questions/route';
import { signingRoutes } from './pages/Signing/route';
import { submissionRoutes } from './pages/Submission/route';
import { taxRoutes } from './pages/Tax/route';

export const reportRoutes = (queryClient: QueryClient) => {
  return {
    path: 'report/:financialYearId',
    element: <Report />,
    errorElement: <span>Something went wrong when loading report</span>,
    children: [questionsRoutes, annualReportRoutes(queryClient), signingRoutes, submissionRoutes, taxRoutes],
  };
};
