import { useEffect, useState } from 'react';
import { useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { MenuItem } from '@simplifiers/ui/Menu';
import { SubMenu } from './SubMenu';
import { SubMenuItemData } from './types';

type SubMenuItemProps = {
  item: SubMenuItemData;
  fullId: string;
};

export const SubMenuItem = ({ item, fullId }: SubMenuItemProps) => {
  const location = useLocation();
  const resolved = useResolvedPath(fullId);

  const match = useMatch({ path: `${resolved.pathname}/*`, end: true });
  const [expanded, setExpanded] = useState<boolean>(!!match);

  const hasChildren = item.parts && item.parts.length > 0;

  useEffect(() => {
    setExpanded(!!match);
    /** Do not add match to dependency array. Match is calculated based on location.
     * And for active menu item "match" is always true,
     * so it won't be possible to collapse active menu item.
     * While we want to automatically expand only when location changes.
     * It should be safe since match depends on location. */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname]);

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <MenuItem to={fullId} title={item.name} active={!!match} expanded={expanded} onToggle={handleToggle}>
      {hasChildren && <SubMenu parts={item.parts} parentId={fullId} />}
    </MenuItem>
  );
};
