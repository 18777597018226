import { NumberCellContent } from './NumericCellContent';
import { StringCellContent } from './StringCellContent';
import { TableCellProps } from './types';

export const TableCellContents = ({ row, column }: TableCellProps) => {
  if (column.value === null || column.value === undefined || column.value === '') return null;

  if (column.dataType === 'string') {
    return <StringCellContent row={row} column={column} />;
  }

  return <NumberCellContent column={column} />;
};
