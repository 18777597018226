import { Navigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { Document } from './Document';
import { annualGeneralMeetingRoutes } from './pages/AnnualGeneralMeeting/route';
import { balanceSheetRoutes } from './pages/BalanceSheet/route';
import { incomeStatementRoutes } from './pages/IncomeStatement/route';
import { managementReportRoutes } from './pages/ManagementReport/route';
import { notesRoutes } from './pages/Notes/route';

export const documentRoutes = (queryClient: QueryClient) => {
  return {
    path: 'document',
    element: <Document />,
    errorElement: <span>Something went wrong when loading document</span>,
    children: [
      { index: true, element: <Navigate to="income-statement" replace /> },
      { path: '*', element: <Navigate to="income-statement" replace /> },
      incomeStatementRoutes,
      balanceSheetRoutes(queryClient),
      notesRoutes,
      managementReportRoutes,
      annualGeneralMeetingRoutes,
    ],
  };
};
