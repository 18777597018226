import { SecondaryButton } from '@simplifiers/ui/Button';
import { Container } from '@simplifiers/ui/Layout/Container';
import { PageDescription, PageTitle, Text } from '@simplifiers/ui/Typography';
import { VouchersList } from './VouchersList';

export function Vouchers() {
  return (
    <>
      <PageTitle>Bekräfta bokföringen</PageTitle>
      <PageDescription>
        <Text as="p">
          Här visas de bokföringsunderlag som skapats i samband med frågorna du besvarat. Kontrollera så att du känner
          dig nöjd med dem och klicka därefter på knappen Bokför alla. Verifikationer skapas då upp i Fortnox bokföring.
        </Text>
      </PageDescription>
      <VouchersList />
      <Container paddingVertical={{ base: 'md' }}>
        <SecondaryButton onClick={() => {}}>Bokför alla</SecondaryButton>
      </Container>
    </>
  );
}
