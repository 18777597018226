import { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@simplifiers/ui/Table';
import classNames from 'classnames';
import { ReportColumn, ReportRow } from '../../../../types';
import styles from './AnnualReportTable.module.css';
import { TableCellContents } from './TableCellContents';
import { TableHeaderContent } from './TableHeaderContent';
import { AnnualReportTableProps } from './types';

const getCellClassName = (cell: ReportColumn) => {
  return classNames(styles[`${cell.dataType}Cell`]);
};

const getRowClassName = (row: ReportRow): string => {
  return classNames(styles.baseRow, styles[`${row.type}`], styles[`level${row.level}`]);
};

export function AnnualReportTable(props: AnnualReportTableProps) {
  const { data, areAccountsShown } = props;

  const filteredRows = useMemo((): ReportRow[] => {
    const newFilteredRows = areAccountsShown ? data.rows : data.rows.filter((row) => row.type !== 'account');

    return newFilteredRows;
  }, [data.rows, areAccountsShown]);

  return (
    <Table
      tableContainerClassName={styles.annualReportTable}
      tableHeaderClassName={styles.tableHeader}
      tableBodyClassName={styles.tableBody}
    >
      <TableHead>
        {data.columns.map((col) => (
          <TableHeader key={col.id} align={col.dataType === 'number' ? 'right' : 'left'}>
            <TableHeaderContent column={col} />
          </TableHeader>
        ))}
      </TableHead>

      <TableBody>
        {filteredRows.map((row) => {
          if (row.type === 'spacer') {
            return (
              <TableRow key={row.idFullPath} className={styles.spacer}>
                <TableCell colSpan={data.columns.length + 1} />
              </TableRow>
            );
          }

          if (row.cells) {
            return (
              <TableRow key={row.idFullPath} className={getRowClassName(row)}>
                {row.cells.map((cell) => (
                  <TableCell key={`${row.id}-${cell.id}`} className={getCellClassName(cell)}>
                    <TableCellContents row={row} column={cell} />
                  </TableCell>
                ))}
              </TableRow>
            );
          }

          return null;
        })}
      </TableBody>
    </Table>
  );
}
