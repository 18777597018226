import { client } from 'api/config';
import { basicCompanyInformationQuery } from 'routes/pages/Start/pages/BasicInformation/loader';
import { getFounder } from 'routes/pages/Start/pages/BasicInformation/utils/filters';
import { Text, Title } from '@simplifiers/ui/Typography';
import { useQuery } from '@tanstack/react-query';
import { annualReportQuery } from '../../loader';
import { AboutCompany } from './AboutCompany';
import { IntroductionText } from './IntroductionText';
import styles from './ManagementReport.module.css';
import { MultiYearOverview } from './MultiYearOverview';
import { ResultsDisposition } from './ResultsDisposition/ResultsDisposition';
import { MultiYearDefinition } from './components/MultiYearDefinition';

export const ManagementReport = () => {
  const { data: annualReport, isLoading: isLoadingAnnualReport } = useQuery(annualReportQuery());
  const { data: information, isLoading: isLoadingBasicInformation } = useQuery(basicCompanyInformationQuery(client.id));

  if (isLoadingAnnualReport || isLoadingBasicInformation) {
    return <div>Loading...</div>;
  }

  if (annualReport == null || information == null) {
    return null;
  }

  const { managementReport } = annualReport;
  const { multiYearOverview } = managementReport;

  return (
    <>
      <Title large className={styles.spacer}>
        Förvaltningsberättelse
      </Title>
      <Text>Läs igenom och kontrollera uppgifterna i förvaltningsberättelsen.</Text>

      <IntroductionText companyName={information.name} founder={getFounder(information.representatives)} />

      <AboutCompany companyActivity={information.businessDescription} registeredOffice={information.registeredOffice} />

      <MultiYearOverview multiYearOverview={multiYearOverview} />

      <MultiYearDefinition />

      {/* NOTE: Not ready yet  */}
      {/* <ChangesInEquity changesInEquity={changesInEquity} /> */}

      <ResultsDisposition />
    </>
  );
};
