import { IconTooltip } from '@simplifiers/ui/IconTooltip/IconTooltip';
import { When } from '@simplifiers/ui/When';
import { ReportRow } from '../../../../../types';

const accountRange = (accounts?: string) => accounts?.replaceAll(':', '-').replaceAll('+', ', ');

interface TooltipCellContentProps {
  row: ReportRow;
}

export const TooltipCellContent = ({ row }: TooltipCellContentProps) => {
  return (
    <IconTooltip
      icon="circle-info"
      text={
        <>
          <When isTrue={!!row.tooltip?.accounts}>
            <p>
              <strong>Kontointervall: </strong>
              {accountRange(row.tooltip?.accounts)}
            </p>
          </When>
          <p>
            <strong>Beskrivning: </strong>
            {row.tooltip?.tooltip}
          </p>
        </>
      }
      position="left"
    />
  );
};
