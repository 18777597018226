import { Grid } from '@simplifiers/ui/Layout/Grid';
import {
  GridTable,
  GridTableBody,
  GridTableCell,
  GridTableHeader,
  GridTableRow,
} from '@simplifiers/ui/Table/GridTable';
import { Caption } from '@simplifiers/ui/Typography';
import { When } from '@simplifiers/ui/When';
import { ResultsDispositionRow } from '../../../resultatDispositionExample';

interface ResultsDispositionTableProps {
  rows: ResultsDispositionRow[];
  title?: string;
}

export const ResultsDispositionTable = ({ rows = [], title }: ResultsDispositionTableProps) => {
  return (
    <>
      {title && <Caption>{title}</Caption>}
      <Grid
        columns={{
          base: 2,
        }}
        colGap={'lg'}
      >
        <GridTable>
          <GridTableBody>
            {rows.map((row) => (
              <GridTableRow key={row.id}>
                {row.cells.map((cell) => (
                  <When
                    key={cell.id}
                    isTrue={cell.dataType === 'string'}
                    fallback={<GridTableCell>{cell.value}</GridTableCell>}
                  >
                    <GridTableHeader scope="row">{cell.value}</GridTableHeader>
                  </When>
                ))}
              </GridTableRow>
            ))}
          </GridTableBody>
        </GridTable>
      </Grid>
    </>
  );
};
