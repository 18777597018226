import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MenuItem } from '@simplifiers/ui/Menu';
import { MenuItemInfo } from '../../menu';
import { SubMenu } from './SubMenu';

type SubMenuListItemProps = {
  menuItem: MenuItemInfo;
  expanded: boolean;
  onExpand: (id: string) => void;
};

export const SubMenuListItem = ({ menuItem, onExpand, expanded }: SubMenuListItemProps) => {
  const { questionId } = useParams();

  const handleToggleMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    expanded ? onExpand('') : onExpand(menuItem.id);
  };

  useEffect(() => {
    if (questionId && menuItem.questionsIds.includes(questionId)) {
      onExpand(menuItem.id);
    }
  }, [questionId, menuItem.questionsIds, menuItem.id, onExpand]);

  return (
    <MenuItem
      to={`../questions/${menuItem.link}`}
      title={menuItem.name}
      active={!!questionId && menuItem.questionsIds.includes(questionId)}
      completed={menuItem.isAnswered}
      expanded={expanded}
      onToggle={handleToggleMenu}
    >
      {menuItem.items.length > 0 && <SubMenu items={menuItem.items} />}
    </MenuItem>
  );
};
