import React from 'react';

type GridTableProps = {
  children: React.ReactNode;
};

export const GridTableBody = (props: GridTableProps) => {
  const { children } = props;

  return <tbody>{children}</tbody>;
};
