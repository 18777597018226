import { useCallback, useEffect, useState } from 'react';
import { Toaster as FToaster, toastCreator as creator } from '@fnox/eternal-smooth-ui';
import { MessageEntry } from '@fnox/eternal-smooth-ui/dist/types/components/Toast/Toaster';

type ToastProps = {
  message: string;
  type: 'info' | 'success' | 'error' | 'warning';
  isToastShown: boolean;
  onToasterClosed: () => void;
};

const getToasterFromType = {
  info: creator.infoMessage,
  success: creator.successMessage,
  error: creator.errorMessage,
  warning: creator.warningMessage,
};

/**
 * How to use it
 * <Toaster
      message={'This is toast'}
      type={'success'}
      isToastShown={isToasterShown}
      onToasterClosed={() => setIsToasterShown(false)}
    />
 */

export const Toaster = (props: ToastProps) => {
  const { message, type, isToastShown, onToasterClosed } = props;

  const [entities, setEntities] = useState<MessageEntry[]>([]);
  const [id, setId] = useState(0);

  const closeToast = useCallback(
    (toastId: number) => setEntities((e) => e.filter((e) => e.id !== toastId)),
    [setEntities]
  );

  useEffect(() => {
    if (isToastShown) {
      const toastCreator = getToasterFromType[type];
      setEntities([...entities, { ...toastCreator('100', message), id }]);
      setId(id + 1);
      onToasterClosed();
    }
  }, [isToastShown, message, id, entities, type, onToasterClosed]);

  return <FToaster entries={entities} closeToast={closeToast} />;
};
