import { QueryClient } from '@tanstack/react-query';
import {
  AnnualReport,
  BalanceSheet,
  DocumentationReference,
  IncomeStatement,
  ManagementReport,
  ManagementReportPart,
  ReportColumn,
  ReportRow,
} from '../../types';
import {
  AgoyBalanceSheet,
  AgoyCell,
  AgoyCellValue,
  AgoyChangesInEquity,
  AgoyManagementReport,
  AgoyMultiYearOverview,
  AgoySettings,
  AgoyTableColumn,
  AgoyTableData,
  AgoyTableRow,
} from './agoyTypes';
import { getAnnualReportDocument } from './api';
import documentationExample from './documentationExample.json';
import { columnLabelFormatter } from './formatters';

export const annualReportQuery = () => ({
  queryKey: ['annualReport'],
  queryFn: async () => getAnnualReport(),
});

export const annualReportLoader = (queryClient: QueryClient) => async () => {
  const query = annualReportQuery();
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
};

export const getAnnualReport = async (): Promise<AnnualReport> => {
  const annualReport = await getAnnualReportDocument();
  const periodsTooltip = getPeriodsTooltip(annualReport?.settings.section);

  // TODO: Add the mock data for testing purposes but it should return null otherwise
  const documentation =
    annualReport && 'documentation' in annualReport
      ? (annualReport.documentation as DocumentationReference)
      : documentationExample;

  return {
    balanceSheet: getBalanceSheet(annualReport?.balanceSheet.section, documentation as DocumentationReference),
    incomeStatement: getIncomeStatement(
      annualReport?.incomeStatement.section.table,
      documentation as DocumentationReference
    ),
    managementReport: getManagementReport(annualReport?.managementReport, periodsTooltip),
  };
};

export const getBalanceSheet = (
  balanceSheet: AgoyBalanceSheet | null,
  documentation?: DocumentationReference
): BalanceSheet => {
  if (balanceSheet == null) {
    return {
      assets: { columns: [], rows: [] },
      equityAndLiabilities: { columns: [], rows: [] },
    };
  }

  const assetsColumns = extractColumns(columnLabelFormatter(balanceSheet.assets.columns, 'Tillgångar'));

  const assetsRows = extractRows(
    balanceSheet.assets.rows ?? [],
    1,
    assetsColumns,
    'balanceSheet.section.assets',
    documentation
  );

  const equityColumns = extractColumns(columnLabelFormatter(balanceSheet.assets.columns, 'Eget kapital och skulder'));
  const equityAndLiabilitiesRows = extractRows(
    balanceSheet.equityAndLiabilities.rows ?? [],
    1,
    equityColumns,
    'balanceSheet.section.equityAndLiabilities',
    documentation
  );

  return {
    documentation,
    assets: { columns: assetsColumns, rows: assetsRows },
    equityAndLiabilities: { columns: equityColumns, rows: equityAndLiabilitiesRows },
  };
};

export const getIncomeStatement = (
  incomeStatement: AgoyTableData | null,
  documentation?: DocumentationReference
): IncomeStatement => {
  if (incomeStatement == null) {
    return {
      columns: [],
      rows: [],
    };
  }

  const columns = extractColumns(columnLabelFormatter(incomeStatement.columns, ''));
  const rows = extractRows(incomeStatement.rows ?? [], 1, columns, 'incomeStatement.section.table', documentation);

  return {
    columns,
    rows,
  };
};

const extractColumns = (columns: AgoyTableColumn[]): ReportColumn[] => {
  const getDataType = (col: AgoyTableColumn): ReportColumn['dataType'] => {
    if (col.dataType === 'numeric') {
      return 'number';
    }

    return 'string';
  };

  return columns.map((col) => {
    return {
      id: col.id,
      dataType: getDataType(col),
      value: col.label ?? '',
    };
  });
};

const extractRows = (
  rows: AgoyTableRow[],
  level: number,
  simplifiedColumns: ReportColumn[],
  basePath: string,
  documentation?: DocumentationReference
): ReportRow[] => {
  let allRows: ReportRow[] = [];

  const getType = (level: number, row: AgoyTableRow): ReportRow['type'] => {
    if (level === 1 && !['alwaysDisplaySum', 'sum', 'spacer'].includes(row.type || '')) {
      return 'section';
    }

    if (row.type === 'alwaysDisplaySum') {
      return 'sum';
    }

    if (row.type === 'alwaysDisplay') {
      return 'row';
    }

    return row.type as ReportRow['type'];
  };

  rows.forEach((row: AgoyTableRow) => {
    if (!row.cells && row.type !== 'spacer') {
      return;
    }

    const idFullPath = `${basePath}.${row.id}`;

    const tooltip = documentation?.[idFullPath] || null;

    allRows.push({
      id: row.id,
      type: getType(level, row),
      level,
      ...(row.type !== 'spacer' ? { cells: extractCells(row, simplifiedColumns) } : {}),
      idFullPath,
      tooltip,
    });

    if (row.rows && row.rows.length > 0) {
      allRows = allRows.concat(extractRows(row.rows, level + 1, simplifiedColumns, idFullPath, documentation));
    }
  });

  return allRows;
};

const extractCells = (row: AgoyTableRow, simplifiedColumns: ReportColumn[]): ReportColumn[] => {
  const columnsKeys = simplifiedColumns.map((col) => col.id);

  const cells = row.cells ?? {};

  const getDataType = (id: string): ReportColumn['dataType'] => {
    const typeMap = {
      label: 'string',
      notes: 'number',
      year: 'number',
      previousYear: 'number',
    };

    return typeMap[id];
  };

  if (row.cells == null) {
    return [
      {
        id: 'label',
        dataType: 'string',
        value: '',
      },
    ];
  }

  return columnsKeys.map((key) => {
    const value =
      row.type === 'account' && key === 'label' ? `${row.id} ${cells['label']?.value ?? ''}` : cells[key]?.value ?? '';

    return {
      id: key,
      dataType: getDataType(key),
      value: getSafeCellValue(value) as string | number,
    };
  });
};

export const getManagementReport = (
  managementReport: AgoyManagementReport,
  periodsTooltip: PeriodsTooltip
): ManagementReport => {
  const getSafeValue = (item: { value: string }, defaultValue: string = ''): string => item?.value || defaultValue;

  const processColumns = (columns: AgoyTableColumn[], title?: string): ReportColumn[] =>
    columns
      .filter((col) => !col.id.includes('Warning'))
      .map((col) => ({
        id: col.id,
        dataType: col.id === 'label' ? 'string' : 'number',
        value: col.id === 'label' && title ? title : col.label ?? '',
        tooltip: col.id.includes('period') ? periodsTooltip[col.id] : null,
      }));

  const processCells = (cells: AgoyCell, simplifiedColumns: ReportColumn[]): ReportColumn[] => {
    const columnsKeys = simplifiedColumns.map((col) => col.id);

    return columnsKeys.map((key) => {
      const value = cells[key]?.value ?? '';

      return {
        id: key,
        dataType: ['ref', 'number'].includes(cells[key]?.type) ? 'number' : 'string',
        value: value == null ? 0 : (getSafeCellValue(value) as string | number),
      };
    });
  };

  const processRows = (rows: AgoyTableRow[], simplifiedColumns: ReportColumn[]): ReportRow[] =>
    rows
      .map((row) => ({
        id: row.id,
        type: 'row' as ReportRow['type'],
        level: 1,
        cells: processCells(row.cells || {}, simplifiedColumns),
      }))
      .filter((row) => row != null);

  const extractMultiYearOverview = (overview: AgoyMultiYearOverview): ManagementReportPart => {
    const simplifiedColumns = processColumns(overview?.table?.columns || [], 'Konto');

    return {
      label: getSafeValue(overview?.multiYearLabel),
      commentLabel: getSafeValue(overview?.multiYearTextLabel),
      comment: getSafeValue(overview?.multiYearText),
      table: {
        columns: simplifiedColumns,
        rows: processRows(overview?.table?.rows || [], simplifiedColumns),
      },
    };
  };

  const extractChangesInEquity = (changesInEquity: AgoyChangesInEquity): ManagementReportPart => {
    const simplifiedColumns = processColumns(changesInEquity?.table?.columns || []);
    return {
      label: getSafeValue(changesInEquity?.title),
      commentLabel: getSafeValue(changesInEquity?.commentLabel),
      comment: getSafeValue(changesInEquity?.comment),
      table: {
        columns: simplifiedColumns,
        rows: processRows(changesInEquity?.table?.rows[0]?.rows || [], simplifiedColumns),
      },
    };
  };

  if (managementReport == null) {
    return {
      multiYearOverview: {
        label: '',
        commentLabel: '',
        comment: '',
        table: {
          columns: [],
          rows: [],
        },
      },
      changesInEquity: {
        label: '',
        commentLabel: '',
        comment: '',
        table: {
          columns: [],
          rows: [],
        },
      },
    };
  }

  return {
    multiYearOverview: extractMultiYearOverview(managementReport.multiYearOverview),
    changesInEquity: extractChangesInEquity(managementReport.changesInEquity),
  };
};

const formatPeriodTooltip = (start?: string, end?: string): string => {
  if (!start && !end) return '';
  return `${start || ''}${start && end ? ' - ' : ''}${end || ''}`.trim();
};

type PeriodsTooltip = {
  period0: string;
  period1: string;
};

const getPeriodsTooltip = (settings: AgoySettings): PeriodsTooltip => {
  const currentPeriodTooltip = formatPeriodTooltip(settings.periodStart?.value, settings.periodEnd?.value);

  const previousPeriodTooltip = formatPeriodTooltip(
    settings.previousPeriodStart?.value,
    settings.previousPeriodEnd?.value
  );

  return {
    period0: currentPeriodTooltip,
    period1: previousPeriodTooltip,
  };
};

const getSafeCellValue = (value: AgoyCellValue) => {
  return typeof value === 'object' && value?.error ? '' : value;
};
