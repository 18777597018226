import { useEffect, useMemo } from 'react';
import { Outlet, useMatch, useNavigate, useOutletContext, useResolvedPath } from 'react-router-dom';
import { When } from '@simplifiers/ui/When';
import { useQuery } from '@tanstack/react-query';
import { Content } from '../../components/Content';
import { Navigation } from '../../components/Navigation';
import { NavigationContext } from '../../types';
import { Progress } from './components/Progress';
import { SubMenu } from './components/SubMenu';
import { getOrderedQuestionsIds, getQuestionByNumber } from './data';
import { getMenuItems } from './menu';
import { questionsQuery } from './query';

export function Questions() {
  const navigate = useNavigate();
  const resolved = useResolvedPath('');
  const match = useMatch({ path: resolved.pathname, end: true });
  const { collapsed, toggleNavigation } = useOutletContext<NavigationContext>();
  const { data, isLoading } = useQuery(questionsQuery());

  useEffect(() => {
    if (match) {
      const firstQuestion = getQuestionByNumber(1, data?.questions);
      if (firstQuestion) {
        navigate(`${firstQuestion?.id}`);
      }
    }
  }, [match, navigate, data]);

  const orderedQuestionsIds = useMemo(() => getOrderedQuestionsIds(data?.questions), [data?.questions]);

  const menuItems = useMemo(
    () => getMenuItems(data?.structure, data?.categories, data?.questions, orderedQuestionsIds),
    [data?.questions, data?.structure, data?.categories, orderedQuestionsIds]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navigation activeId="questions" collapsed={collapsed} onToggle={toggleNavigation}>
        <When isTrue={!collapsed}>
          <SubMenu items={menuItems} />
        </When>
      </Navigation>
      <Content>
        <Progress form={data} orderedQuestionsIds={orderedQuestionsIds} />
        <Outlet context={{ orderedQuestionsIds }} />
      </Content>
    </>
  );
}
