import { Fragment } from 'react';
import { IconButton } from '@simplifiers/ui/Button';
import styles from './VouchersList.module.css';

const exampleVouchers = [
  {
    id: '1',
    series: 'I - Bokslut',
    date: '2024-08-01',
    description: 'Lokalkostnader',
    transactions: [
      {
        id: '11',
        creditAccount: '1710 Förutbetalda hyreskostnader',
        debitAccount: '5010 Lokalhyra',
        debit: '4 000,00',
        credit: '4 000,00',
      },
      {
        id: '12',
        creditAccount: '2960 Uppsluppna räntekostnader',
        debitAccount: '8400 Räntekostnader',
        debit: '2 999,20',
        credit: '2 999,20',
      },
    ],
  },
  {
    id: '2',
    series: 'I - Bokslut',
    date: '2024-08-01',
    description: 'Preliminär F-skatt januari 2024, kostnadsföring',
    transactions: [
      {
        id: '21',
        debitAccount: '2510 Skatteskulder',
        creditAccount: '8910 Skatt som belastar årets resultant',
        debit: '1 874 538,65',
        credit: '1 874 538,65',
      },
    ],
  },
];

export function VouchersList() {
  return (
    <table className={styles.voucherTable}>
      <thead>
        <tr>
          <th>Ver.serie</th>
          <th>Bokföringsdatum</th>
          <th>Beskrivning/Konto</th>
          <th>Debet</th>
          <th>Kredit</th>
          <th></th>
        </tr>
      </thead>
      {exampleVouchers.map((v) => (
        <tbody key={v.id}>
          <tr>
            <th>{v.series}</th>
            <th>{v.date}</th>
            <th>{v.description}</th>
            <th></th>
            <th></th>
            <th>
              <IconButton name="pen" ariaLabel="Edit voucher" onClick={() => {}} />
            </th>
          </tr>
          {v.transactions.map((t) => (
            <Fragment key={t.id}>
              <tr>
                <td></td>
                <td></td>
                <td>{t.creditAccount}</td>
                <td></td>
                <td>{t.credit}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>{t.debitAccount}</td>
                <td>{t.debit}</td>
                <td></td>
                <td></td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      ))}
      <tfoot>
        <tr>
          <td>Antal verifikat</td>
          <td>{exampleVouchers.length}</td>
          <td>Omslutning</td>
          <td>4 588 124,2</td>
          <td>4 588 124,2</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );
}
