export const reportNavigation = [
  {
    id: 'questions',
    name: 'Bokslutsfrågor',
    status: 'complete',
  },
  {
    id: 'annual-report',
    name: 'Årsredovisning',
    status: 'incomplete',
    parts: [
      {
        id: 'document',
        name: 'Dokument',
        parts: [
          { id: 'income-statement', name: 'Resultaträkning', status: 'incomplete' },
          { id: 'balance-sheet', name: 'Balansräkning', status: 'incomplete' },
          { id: 'notes', name: 'Noter', status: 'incomplete' },
          { id: 'management-report', name: 'Förvaltningsberättelse', status: 'incomplete' },
          { id: 'annual-general-meeting', name: 'Årsstämmoprotokoll', status: 'incomplete' },
        ],
      },
      {
        id: 'payment-and-vouchers',
        name: 'Betalning och bokföring',
        parts: [
          { id: 'preview-payment', name: 'Förhandsgranskning och betalning', status: 'incomplete' },
          { id: 'vouchers', name: 'Vouchers', status: 'incomplete' },
        ],
      },
    ],
  },
  {
    id: 'signing',
    name: 'Signering',
    status: 'incomplete',
    parts: [
      { id: 'signatories', name: 'Undertecknare', status: 'incomplete' },
      { id: 'annual-report-signing', name: 'Årsredovisning signering', status: 'incomplete' },
      { id: 'annual-meeting-signing', name: 'Årsstämmoprotokoll signering', status: 'incomplete' },
    ],
  },
  {
    id: 'submission',
    name: 'Inlämning',
    status: 'incomplete',
    parts: [
      { id: 'digital-submission', name: 'Inlämning till Bolagsverket', status: 'incomplete' },
      { id: 'archive', name: 'Arkivering', status: 'incomplete' },
    ],
  },
  {
    id: 'tax',
    name: 'Skatt',
    status: 'incomplete',
    parts: [
      { id: 'ink2', name: 'INK2', status: 'incomplete' },
      { id: 'k10', name: 'K10', status: 'incomplete' },
      { id: 'digital-submission', name: 'Inlämning till Skatteverket', status: 'incomplete' },
    ],
  },
];
