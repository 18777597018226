import { useState } from 'react';
import { Menu } from '@simplifiers/ui/Menu';
import { MenuItemInfo } from '../../menu';
import { SubMenuListItem } from './SubMenuListItem';

type SubMenuProps = {
  items: Array<MenuItemInfo>;
};

export const SubMenu = ({ items }: SubMenuProps) => {
  const [expandedId, setExpandedId] = useState<string>('');

  if (!items?.length) {
    return null;
  }

  return (
    <Menu>
      {items.map((menuItem) => (
        <SubMenuListItem
          expanded={menuItem.id === expandedId}
          onExpand={setExpandedId}
          key={menuItem.id}
          menuItem={menuItem}
        />
      ))}
    </Menu>
  );
};
