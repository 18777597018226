import { Tooltip } from '@simplifiers/ui/Tooltip/Tooltip';
import { Caption, Title } from '@simplifiers/ui/Typography';
import cx from 'classnames';
import styles from './TitleTooltip.module.css';

type TitleTooltipProps = {
  large?: boolean;
  title: string;
  tooltip?: string;
  className?: string;
};

export const TitleTooltip = ({ large = false, title, tooltip, className }: TitleTooltipProps) => {
  return (
    <div className={cx(styles.titleTooltip, className)}>
      {large ? <Title>{title}</Title> : <Caption>{title}</Caption>}
      <Tooltip className={styles.titleTooltip} icon="circle-info" text={tooltip} position="top" />
    </div>
  );
};
