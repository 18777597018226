import { InlineContent } from '@simplifiers/ui/Layout/InlineContent';
import { When } from '@simplifiers/ui/When';
import { ReportRow } from '../../../../../types';
import styles from '../AnnualReportTable.module.css';
import { TooltipCellContent } from './TooltipCellContent';
import { TableCellProps } from './types';

const isRowWithTooltip = (row?: ReportRow): boolean => {
  if (!row) return false;
  return 'tooltip' in row && row.tooltip !== null && row.tooltip?.tooltip !== undefined;
};

const getAccountInfo = (accountLabel: string | number) => {
  const text = accountLabel.toString();
  return { number: text.slice(0, 4), name: text.slice(4) };
};

export const StringCellContent = ({ row, column }: TableCellProps) => {
  const hasRowTooltipDescription = isRowWithTooltip(row);

  const isAccountLabel = row.type === 'account' && column.id === 'label';
  const accountInfo = isAccountLabel && getAccountInfo(column.value);

  return (
    <InlineContent justifyContent="space-between" alignItems="center">
      <When isTrue={isAccountLabel} fallback={column.value}>
        <div>
          <span className={styles.accountNumber}>{accountInfo?.number}</span>
          {accountInfo?.name}
        </div>
      </When>
      {hasRowTooltipDescription && <TooltipCellContent row={row} />}
    </InlineContent>
  );
};
