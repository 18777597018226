import { apiService } from 'api';
import exampleBasicInformation from './basicInformationExample.json';
import { OrganizationBasicInfo, RepresentativeRoleCode, RepresentativeRoleText } from './types';

export async function getCompanyInformation(clientId: string): Promise<OrganizationBasicInfo | null> {
  try {
    const data = await apiService.getBvCompanyInformation({ clientId });

    if (data == null) {
      return null;
    }

    return {
      organizationNumber: data.orgnr,
      name: data.namn,
      status: data.status.map((status) => ({
        code: status.kod,
        text: status.text,
        date: status.datum,
      })),
      // INFO: currently the API does not return this value
      businessDescription: 'Beskrivning av verksamheten',
      // INFO: currently the API does not return this value
      registeredOffice: 'Stockholm',
      periods: data.rakenskapsperioder.map((period) => ({
        from: period.from,
        to: period.tom,
        requiresAuditLog: period.kravPaRevisionsberattelse === 'ja',
      })),
      representatives: data.foretradare.map((representative) => ({
        firstName: representative.fornamn || '',
        lastName: representative.namn || '',
        personalNumber: representative.personnummer || '',
        otherIdentity: representative.annanIdentitet,
        roles: representative.funktioner.map((role) => ({
          code: role.kod as RepresentativeRoleCode,
          text: role.text as RepresentativeRoleText,
        })),
      })),
    };
  } catch (err) {
    /**
     * TODO: this is temporary solution until we have authentication and we can
     * authenticate and get the correct annual report data
     * it is needed for our test2 environment to have some data to show how balance sheet looks like
     */

    return exampleBasicInformation as OrganizationBasicInfo;
  }
}
