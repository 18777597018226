import accounting from 'accounting';

export const formatNumber = (number: number | string): string => {
  const num = typeof number === 'string' ? parseFloat(number) : number;

  if (isNaN(num)) return '0,00';

  // Format numbers with two decimals, space as thousands separator and comma as decimal separator
  return accounting.formatNumber(num, 2, ' ', ',');
};
