import React from 'react';
import cx from 'classnames';
import styles from './GridTable.module.css';

type GridTableHeaderProps = {
  children: React.ReactNode;
  align?: 'left' | 'right' | 'center';
  scope?: 'col' | 'row';
};

export const GridTableHeader = (props: GridTableHeaderProps) => {
  const { children, align = 'left', scope = 'col' } = props;

  return (
    <th scope={scope} className={cx({ [styles.alignRight]: align === 'right' })}>
      {children}
    </th>
  );
};
