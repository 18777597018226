import { AlertDialog as FAlertDialog } from '@fnox/eternal-smooth-ui';

type AlertDialog = {
  title: string;
  description: string;
  isOpen: boolean;
  actions: {
    onClick: () => void;
    text: string;
    style?: 'default' | 'cancel' | 'destructive';
    loglabel?: string;
    disabled?: boolean;
    testID?: string;
  }[];
  children?: React.ReactNode;
};

export const AlertDialog = (props: AlertDialog) => {
  const { title, description, isOpen, actions, children } = props;

  return (
    <FAlertDialog title={title} description={description} actions={actions} open={isOpen}>
      {children}
    </FAlertDialog>
  );
};
