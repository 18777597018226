import { AwesomeIcon } from '@fnox/eternal-smooth-ui';
import { IconNameType, IconSize, IconType } from '@simplifiers/ui/Icon/types';

type IconProps = {
  name: IconNameType;
  color?: string;
  size?: IconSize;
  type?: IconType;
  className?: string;
};

export const Icon = (props: IconProps) => {
  const { name, color, size, type, className } = props;

  return <AwesomeIcon name={name} color={color} size={size} type={type} className={className} />;
};
