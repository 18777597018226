import React from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Dropdown, DropdownProps } from './Dropdown';

export type FormDropdownProps<T extends string | number> = UseControllerProps<FieldValues> &
  Pick<DropdownProps<T>, 'title' | 'options' | 'className' | 'disabled' | 'optionLabelFormatter'>;

export const FormDropdown = React.forwardRef(
  <T extends string | number>(
    { title, options, className, disabled, optionLabelFormatter, ...props }: FormDropdownProps<T>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { field } = useController<FieldValues>(props);

    return (
      <Dropdown
        title={title}
        options={options}
        className={className}
        disabled={disabled}
        optionLabelFormatter={optionLabelFormatter}
        {...field}
        ref={ref}
      />
    );
  }
);
