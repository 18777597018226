import { areQuestionsAnswered } from './data';
import { Category, Question, StructureItem } from './types';

export type MenuItemInfo = {
  id: string;
  name: string;
  link: string;
  isAnswered: boolean;
  questionsIds: string[];
  items: Array<MenuItemInfo>;
};

const isRelevantQuestion = (orderedQuestionsIds: Array<string>, item: StructureItem): boolean => {
  return item.type === 'question' && orderedQuestionsIds.includes(item.id);
};

const getRelevantChildrenQuestions = (orderedQuestionsIds: Array<string>, children: Array<StructureItem>) => {
  return children ? children.filter((child) => orderedQuestionsIds.includes(child.id)) : [];
};

export const getMenuItemInfo = (
  item: StructureItem,
  categories: Record<string, Category>,
  questions: Record<string, Question>,
  orderedQuestionsIds: Array<string>
): (Omit<MenuItemInfo, 'items'> & { items: Array<StructureItem> }) | null => {
  let source,
    name,
    link,
    items: Array<StructureItem> = [],
    isAnswered,
    questionsIds;

  switch (item.type) {
    case 'question': {
      source = questions[item.id];
      name = source.shortTitle;
      items = getRelevantChildrenQuestions(orderedQuestionsIds, item.children);
      questionsIds = [item.id, ...items];
      isAnswered = areQuestionsAnswered(questionsIds, questions);
      link = item.id;
      break;
    }
    case 'category': {
      source = categories[item.id];
      name = source.name;
      questionsIds = orderedQuestionsIds.filter((id) => source.questionsIds.includes(id));
      isAnswered = areQuestionsAnswered(questionsIds, questions);

      // if it is category with one direct child question do not render child question as child
      // instead link to that question because it is a duplicate
      if (item.children?.length === 1 && isRelevantQuestion(orderedQuestionsIds, item.children[0])) {
        const child = item.children[0];
        link = child.id;
        items = getRelevantChildrenQuestions(orderedQuestionsIds, child.children);
      } else {
        link = questionsIds[0];
        items = item.children.filter((c) => c.type === 'category' || isRelevantQuestion(orderedQuestionsIds, c));
      }
      break;
    }
    default: {
      return null;
    }
  }

  return { id: item.id, name, link, items, isAnswered, questionsIds };
};

export const getMenuItems = (
  items: Array<StructureItem>,
  categories: Record<string, Category>,
  questions: Record<string, Question>,
  orderedQuestionsIds: Array<string>
): Array<MenuItemInfo> => {
  if (!items?.length || !orderedQuestionsIds.length) {
    return [];
  }

  const result: Array<MenuItemInfo> = [];

  items.forEach((item) => {
    const itemInfo = getMenuItemInfo(item, categories, questions, orderedQuestionsIds);
    if (!itemInfo) {
      return;
    }
    if (itemInfo.items.length) {
      result.push({ ...itemInfo, items: getMenuItems(itemInfo.items, categories, questions, orderedQuestionsIds) });
    } else {
      result.push(itemInfo as unknown as MenuItemInfo);
    }
  });

  return result;
};
