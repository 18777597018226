import { ExternalLink, SecondaryButton, ToggleButton } from '@simplifiers/ui/Button';
import { Text, Title } from '@simplifiers/ui/Typography';
import { Question } from '../../../../types';
import styles from './QuestionDescription.module.css';

type QuestionDescriptionProps = {
  question: Question;
  onAnswer: (answer: string) => void;
  onSnooze: () => void;
};

export function QuestionDescription({ question, onAnswer, onSnooze }: QuestionDescriptionProps) {
  return (
    <div className={styles.question}>
      <Title>{question.title}</Title>
      <Text as="div" className={styles.description}>
        {question.description}
      </Text>

      {question.externalLinks.length > 0 &&
        question.externalLinks.map((l) => (
          <ExternalLink key={l.url} to={l.url}>
            {l.label}
          </ExternalLink>
        ))}

      <div className={styles.actions}>
        <ToggleButton
          iconName="check"
          active={question.answer === 'yes'}
          className={styles.button}
          onClick={() => onAnswer('yes')}
        >
          Ja
        </ToggleButton>

        <ToggleButton
          iconName="check"
          active={question.answer === 'no'}
          className={styles.button}
          onClick={() => onAnswer('no')}
        >
          Nej
        </ToggleButton>

        <SecondaryButton onClick={onSnooze} className={styles.button}>
          Svara senare
        </SecondaryButton>
      </div>
    </div>
  );
}
