import { Outlet, useOutletContext } from 'react-router-dom';
import { When } from '@simplifiers/ui/When';
import { Content } from '../../components/Content';
import { Navigation } from '../../components/Navigation';
import { SubMenu } from '../../components/SubMenu';
import { SubMenuItemData } from '../../components/SubMenu/types';
import { NavigationContext } from '../../types';

const annualReportNav: Array<SubMenuItemData> = [
  {
    id: 'document',
    name: 'Dokument',
    status: 'incomplete',
    parts: [
      { id: 'income-statement', name: 'Resultaträkning', status: 'incomplete' },
      { id: 'balance-sheet', name: 'Balansräkning', status: 'incomplete' },
      { id: 'notes', name: 'Noter', status: 'incomplete' },
      { id: 'management-report', name: 'Förvaltningsberättelse', status: 'incomplete' },
      { id: 'annual-general-meeting', name: 'Årsstämmoprotokoll', status: 'incomplete' },
    ],
  },
  {
    id: 'payment-and-vouchers',
    name: 'Betalning och bokföring',
    status: 'incomplete',
    parts: [
      { id: 'preview-payment', name: 'Förhandsgranskning och betalning', status: 'incomplete' },
      { id: 'vouchers', name: 'Vouchers', status: 'incomplete' },
    ],
  },
];

export function AnnualReport() {
  const { collapsed, toggleNavigation } = useOutletContext<NavigationContext>();

  return (
    <>
      <Navigation activeId="annual-report" collapsed={collapsed} onToggle={toggleNavigation}>
        <When isTrue={!collapsed}>
          <SubMenu parentId="../annual-report" parts={annualReportNav} />
        </When>
      </Navigation>
      <Content>
        <Outlet />
      </Content>
    </>
  );
}
