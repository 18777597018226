import { client } from 'api/config';
import { SharesCompanyAnnualReport } from '@agoy/api-sdk-core';
import { apiService } from 'api';
import { AgoyAnnualReport } from './agoyTypes';
import exampleAnnualReport from './annualReportExample.json';

/**
 * Annual report consists of balances, income statement, and cash flow statement.
 * @returns annual report data
 */
export async function getAnnualReportDocument(): Promise<AgoyAnnualReport | null> {
  const { start, end } = client.financialYears[0];
  const formattedStart = start.replace(/-/g, '');
  const formattedEnd = end.replace(/-/g, '');

  try {
    const annualReport = await apiService.getFullAnnualReport({
      clientid: client.id,
      financialYear: `${formattedStart}-${formattedEnd}`,
    });

    return annualReport as SharesCompanyAnnualReport;
  } catch (err) {
    /**
     * TODO: this is temporary solution until we have authentication and we can
     * authenticate and get the correct annual report data
     * it is needed for our test2 environment to have some data to show how balance sheet looks like
     */

    return exampleAnnualReport as AgoyAnnualReport;
  }
}
