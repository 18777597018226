import { useState } from 'react';
import { ToggleButton } from '@simplifiers/ui/Button';
import { InlineContent } from '@simplifiers/ui/Layout/InlineContent';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Title } from '@simplifiers/ui/Typography';
import { useQuery } from '@tanstack/react-query';
import { AnnualReportTable } from '../../components/AnnualReportTable';
import { annualReportQuery } from '../../loader';

export function BalanceSheet() {
  const { data: annualReport, isLoading } = useQuery(annualReportQuery());
  const [showAccounts, setShowAccounts] = useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (annualReport == null) {
    return null;
  }

  const { balanceSheet } = annualReport;
  const { documentation, assets, equityAndLiabilities } = balanceSheet;

  return (
    <>
      <InlineContent justifyContent="space-between" alignItems="center">
        <Title large>Balansräkning</Title>
        <ToggleButton
          iconName={showAccounts ? 'eye-slash' : 'eye'}
          active
          size="small"
          type="outlined"
          onClick={() => setShowAccounts((prev) => !prev)}
        >
          {showAccounts ? 'Göm konton' : 'Visa konton'}
        </ToggleButton>
      </InlineContent>

      <Section>
        <AnnualReportTable
          data={{ documentation, ...assets }}
          areAccountsShown={showAccounts}
          basePath="balanceSheet"
          sections={['assets']}
        />
      </Section>
      <Section>
        <AnnualReportTable
          data={{ documentation, ...equityAndLiabilities }}
          areAccountsShown={showAccounts}
          basePath="balanceSheet"
          sections={['equityAndLiabilities']}
        />
      </Section>
    </>
  );
}
