import { RouteObject } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { Questions } from './Questions';
import { questionsLoader } from './loader';
import { questionRoutes } from './pages/Question/route';

// TODO: get the query client from parent routes
const queryClient = new QueryClient();

export const questionsRoutes: RouteObject = {
  path: 'questions',
  element: <Questions />,
  loader: questionsLoader(queryClient),
  children: [questionRoutes],
};
