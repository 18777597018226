import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Icon } from '@simplifiers/ui/Icon';
import { SubTitle } from '@simplifiers/ui/Typography';
import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';
import styles from './Dropdown.module.css';
import { DropdownOption } from './DropdownOption';

export type Option<T> = {
  label: string;
  value: T;
};

export type DropdownProps<T> = {
  title?: string;
  name: string;
  value?: T;
  options: Array<Option<T>>;
  onChange: (value: T) => void;
  className?: string;
  disabled?: boolean;
  optionLabelFormatter?: (option: Option<string | number>) => string;
};

export const Dropdown = React.forwardRef(
  <T extends string | number>(
    { title, name, value, options, onChange, className, disabled, optionLabelFormatter }: DropdownProps<T>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: React.ForwardedRef<HTMLInputElement>
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [popperTargetRef, setPopperTargetRef] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles: popperStyles, attributes } = usePopper(popperTargetRef, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'flip', options: { padding: 0 } }],
      strategy: 'fixed',
    });

    const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

    const selectedOption = options.find((o) => o.value === value);
    const expanded = optionsVisible && !!options.length;

    useOnClickOutside(containerRef, () => {
      setOptionsVisible(false);
      setFocusedIndex(null);
    });

    const toggleOptions = (e: React.MouseEvent<unknown>) => {
      if (disabled || options.length === 0 || document.activeElement !== e.target) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      setOptionsVisible(!optionsVisible);
    };

    const handleButtonClick = (e: React.MouseEvent<unknown>) => {
      if (disabled || options.length === 0) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      inputRef.current?.focus();
      setOptionsVisible(!optionsVisible);
    };

    const hideOptionsAndResetFocus = () => {
      setOptionsVisible(false);
      setFocusedIndex(null);
      inputRef.current?.focus();
    };

    const setSelectedValue = (value: T) => {
      hideOptionsAndResetFocus();
      onChange(value);
    };

    const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ' ':
        case 'SpaceBar':
        case 'Enter':
        case 'ArrowDown':
        case 'ArrowUp':
          if (disabled || options.length === 0) {
            return;
          }
          e.preventDefault();
          setOptionsVisible(true);
          setFocusedIndex(selectedOption ? options.indexOf(selectedOption) : 0);
          break;
        default:
          break;
      }
    };

    const handleListKeyDown = (e: React.KeyboardEvent<HTMLOListElement>) => {
      e.preventDefault();
      switch (e.key) {
        case 'Escape':
          hideOptionsAndResetFocus();
          break;
        case 'Tab':
          setOptionsVisible(false);
          setFocusedIndex(null);
          break;
        case 'ArrowUp':
          if (focusedIndex !== null && focusedIndex - 1 >= 0) {
            setFocusedIndex(focusedIndex - 1);
          }
          break;
        case 'ArrowDown':
          if (focusedIndex !== null && focusedIndex !== options.length - 1) {
            setFocusedIndex(focusedIndex + 1);
          }
          break;
        case 'Enter':
        case ' ':
          focusedIndex !== null && setSelectedValue(options[focusedIndex].value);
          break;
        default:
          break;
      }
    };

    return (
      <div ref={containerRef}>
        <div
          className={cx(styles.dropdownContainer, { [styles.expanded]: !disabled && expanded })}
          ref={setPopperTargetRef}
        >
          <button
            type="button"
            onClick={handleButtonClick}
            className={styles.dropdownIconButton}
            tabIndex={-1}
            aria-hidden="true"
          >
            <Icon className={styles.dropdownIcon} name="chevron-down" />
          </button>
          <input
            ref={inputRef}
            id={`dropdown-button-${name}`}
            className={cx(
              styles.dropdownButton,
              {
                [styles.disabled]: disabled,
              },
              className
            )}
            disabled={disabled}
            onClick={toggleOptions}
            onKeyDown={handleButtonKeyDown}
            role="combobox"
            aria-haspopup="true"
            aria-controls={`dropdown-options-list-${name}`}
            aria-expanded={expanded}
            aria-disabled={disabled}
            tabIndex={0}
            value={selectedOption ? selectedOption.value : ''}
            readOnly
          />
        </div>

        {!disabled && expanded && (
          <div
            className={styles.dropdownOptionsContainer}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {title && <SubTitle className={styles.dropdownOptionsTitle}>{title}</SubTitle>}
            <ol
              id={`dropdown-options-list-${name}`}
              className={styles.dropdownOptions}
              onKeyDown={handleListKeyDown}
              tabIndex={-1}
              role="listbox"
              aria-labelledby={`dropdown-button-${name}`}
              aria-activedescendant={focusedIndex !== null ? options[focusedIndex].label : ''}
            >
              {options.map((option, i) => (
                <DropdownOption
                  key={i}
                  label={optionLabelFormatter ? optionLabelFormatter(option) : option.label}
                  value={option.value}
                  focused={focusedIndex === i}
                  selected={value === option.value}
                  onClick={setSelectedValue}
                />
              ))}
            </ol>
          </div>
        )}
      </div>
    );
  }
);
