import { client } from 'api/config';
import { Input } from '@simplifiers/ui/Form/Input';
import { Grid, GridItem } from '@simplifiers/ui/Layout/Grid';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Caption } from '@simplifiers/ui/Typography';
import { useQuery } from '@tanstack/react-query';
import { basicCompanyInformationQuery } from '../../loader';
import { getFounder } from '../../utils/filters';
import { getRepresentativeFullName } from '../../utils/formatters';
import styles from './CompanyInformation.module.css';
import { InformationItem } from './components/InformationItem';

export const CompanyInformation = () => {
  const { data: info, isLoading } = useQuery(basicCompanyInformationQuery(client.id));

  if (isLoading || info == null) {
    return <div>Loading...</div>;
  }

  const getFounderFullName = () => {
    const founder = getFounder(info.representatives);
    if (founder == null) {
      return '';
    }

    return getRepresentativeFullName(founder);
  };

  const items = [
    { title: 'Företagsnamn', value: info.name },
    { title: 'Organisationsnummer', value: info.organizationNumber },
    { title: 'Grundare', value: getFounderFullName() },
    { title: 'Företagsform', value: 'Aktiebolag' },
    { title: 'Antal anställda', value: info.representatives.length.toString() },
    //TODO: we will need to also fetch location data but for now we don't get it from the API
    { title: 'Säte', value: info.registeredOffice },
  ];

  return (
    <Section>
      <Grid columns={{ base: 3 }} rowGap={'xl'}>
        {items.map((item) => (
          <InformationItem key={item.title} title={item.title} value={item.value} />
        ))}

        <GridItem colSpan={{ base: 3 }}>
          <Caption>Om verksamheten</Caption>
          <Input
            name="business-description"
            className={styles.textArea}
            label="Beskrivning av verksamheten"
            value={info.businessDescription}
            multiline
          />
        </GridItem>
      </Grid>
    </Section>
  );
};
