import React, { ComponentProps, FunctionComponentElement } from 'react';
import { Table as FTable } from '@fnox/eternal-smooth-ui';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

const getBodyRows = (children: React.ReactNode): React.ReactNode | React.ReactNode[] =>
  React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === TableBody) {
      return child.props.children;
    }

    return null;
  });

const getHeadCells = (children: React.ReactNode): React.ReactNode | React.ReactNode[] => {
  const head = React.Children.map(children, (child): React.ReactNode | React.ReactNode[] => {
    if (React.isValidElement(child) && child.type === TableHead) {
      return child.props.children;
    }

    return null;
  });

  if (!head) {
    return null;
  }

  return React.Children.map(head, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === TableRow) {
        return child.props.children;
      } else {
        return child;
      }
    }
  });
};

type TableRowHeight = typeof FTable extends (props: { rowHeight: infer T }) => unknown ? T : never;

type TableProps = {
  children: React.ReactNode;
  tableContainerClassName?: string;
  tableHeaderClassName?: string;
  tableBodyClassName?: string;
  rowHeight?: TableRowHeight;
};

export function Table({
  rowHeight = '48',
  tableContainerClassName,
  tableHeaderClassName,
  tableBodyClassName,
  children,
}: TableProps) {
  return (
    <FTable
      rowHeight={rowHeight}
      tableContainer={tableContainerClassName}
      tableBodyClassName={tableBodyClassName}
      tableHeaderClassName={tableHeaderClassName}
      rows={getBodyRows(children) as FunctionComponentElement<ComponentProps<typeof TableRow>>[]}
    >
      {getHeadCells(children) as FunctionComponentElement<ComponentProps<typeof TableHeader>>[]}
    </FTable>
  );
}
