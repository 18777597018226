import { eternalSmoothIcons } from '@fnox/eternal-smooth-ui';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAnchor,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBuildingNgo,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCircle,
  faCloudUpload,
  faComment,
  faEye,
  faEyeSlash,
  faFile,
  faHouse,
  faLightbulbOn,
  faPen,
  faQuestionCircle,
  faSend,
  faSnooze,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

/**
 * icons from the ui-library package are added here to be used in the app
 * If you want to add more icons from the light pack, you can do it here
 * @example
 * import { fa360Degrees, faAirConditioner } from '@fortawesome/pro-light-svg-icons';
 * library.add(eternalSmoothIcons, fa360Degrees, faAirConditioner);
 */

// You can choose to add entire light icon pack at once or pick specific icons one by one
// library.add(fal);
library.add(
  eternalSmoothIcons,
  faAnchor,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBuildingNgo,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faCircle,
  faCloudUpload,
  faComment,
  faEye,
  faEyeSlash,
  faFile,
  faHouse,
  faLightbulbOn,
  faQuestionCircle,
  faSend,
  faSnooze,
  faTrash,
  faPen
);

library.add(faCircleCheck);
