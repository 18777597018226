import { AgoyTableColumn } from '../pages/Document/agoyTypes';

/**
 * Formats header column labels for a table.
 *
 * This function processes an array of TableColumn objects, modifying their labels
 * based on specific conditions:
 * - If the column id is 'notes', the label is set to 'Not'.
 * - If the column id is 'label', the label is set to the provided 'part' string.
 * - For all other columns, the original label is retained.
 *
 * @param columns - The array of table columns to be formatted.
 * @param part - The label to be used for the 'label' column.
 * @returns {TableColumn[]} A new array of TableColumn objects with formatted labels.
 *
 * @example
 * const columns = [
 *   { id: 'label', label: '' },
 *   { id: 'notes', label: '' },
 *   { id: 'year', label: '2022-01-01' }
 * ];
 * const formattedColumns = columnLabelFormatter(columns, 'New Label');
 * // Result:
 * // [
 * //   { id: 'label', label: 'New Label' },
 * //   { id: 'notes', label: 'Not' },
 * //   { id: 'year', label: '2022-01-01' }
 * // ]
 */
export const columnLabelFormatter = (columns: AgoyTableColumn[], part: string): AgoyTableColumn[] => {
  return columns.map((col) => {
    if (col.id === 'notes') {
      return { ...col, label: 'Not' };
    }

    if (col.id === 'label') {
      return { ...col, label: part };
    }

    return col;
  });
};

/**
 * Formats dates for header column labels.
 *
 * This function breaks down dates on two lines when there are two dates
 * in the format 'YYYY-MM-DD-YYYY-MM-DD'. This allows the dates to be displayed
 * in a more readable format.
 *
 * @param label - A date in the format 'YYYY-MM-DD-YYYY-MM-DD'.
 * @returns A date formatted in two parts with a line break in-between.
 *
 * @example
 * const columns = [
 *   { id: 'label', label: '' },
 *   { id: 'notes', label: '' },
 *   { id: 'year', label: '2022-01-01-2022-12-31' }
 * ];
 * const formattedColumns = columnLabelFormatter(columns, 'New Label');
 * // Result:
 * // [
 * //   { id: 'label', label: 'New Label' },
 * //   { id: 'notes', label: 'Not' },
 * //   { id: 'amount', label: '2022-01-01\n2022-12-31' }
 * // ]
 */
export const formatYearColumnLabel = (label: string | undefined): string => {
  if (!label) return '';

  const match = /^(\d{4}-\d{2}-\d{2})(-.*)?$/.exec(label);

  if (match) {
    const startDate = match[1];
    const endDate = match[2]?.substring(1);

    if (startDate && endDate) {
      const formattedDate = startDate + '\n' + endDate;
      return formattedDate;
    }
  }

  if (label.endsWith('-')) {
    return label.replace(/-?$/, '');
  }

  if (label.startsWith('-')) {
    return label.replace(/^-/, '');
  }

  return label;
};
