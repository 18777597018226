import data from 'api/questions.json';
import { getAnswersFromLocalStorage, saveAnswerToLocalStorage } from 'localStorage/localStorage';
import {
  Answer,
  Category,
  Dependency,
  QBCategory,
  QBQuestion,
  QBStructure,
  Question,
  QuestionsForm,
  StructureItem,
} from './types';

export const getQuestionsStructure = (): QuestionsForm => {
  return processQuestionsStructure(data);
};

export const getAllAnswers = () => {
  return getAnswersFromLocalStorage();
};

export const answerQuestions = async (answers: Array<Answer>) => {
  saveAnswerToLocalStorage(answers);
};

/* TODO: These functions are temporary, later we will get same structure from api */
function reorganizeStructure(categories: Array<QBCategory>) {
  const organizeQuestions = (questions: Array<QBQuestion>): Array<StructureItem> => {
    const questionMap: Record<string, StructureItem> = {};
    const rootQuestions: Array<StructureItem> = [];

    questions.forEach((question) => {
      questionMap[question.id] = {
        type: 'question',
        id: question.id,
        children: [],
      };
    });

    questions.forEach((question) => {
      if (question.dependsOnQuestions.length) {
        for (const dependency of question.dependsOnQuestions) {
          questionMap[dependency.questionId].children.push(questionMap[question.id]);
        }
      } else {
        rootQuestions.push(questionMap[question.id]);
      }
    });

    return rootQuestions;
  };

  const organizeCategories = (categories: Array<QBCategory> | undefined) => {
    return categories
      ? categories.map((category) => {
          return {
            type: 'category',
            id: category.id,
            children: [...organizeQuestions(category.questions), ...organizeCategories(category.subCategories)],
          };
        })
      : [];
  };

  return organizeCategories(categories);
}

const processQuestionsStructure = (data: QBStructure): QuestionsForm => {
  const flatQuestions: Record<string, Question> = {};
  const flatCategories: Record<string, Category> = {};
  const answers: Array<Answer> = getAllAnswers();

  const processCategories = (categories) => {
    for (const category of categories) {
      const questionsIds: string[] = [];

      for (const question of category.questions) {
        const answer = answers.find((a) => a.questionId === question.id)?.answer;

        flatQuestions[question.id] = {
          ...question,
          orderNumber: Object.keys(flatQuestions).length + 1,
          categoryId: category.id,
          categoryName: category.name,
          answer,
          dependentQuestionsIds: [],
        };

        questionsIds.push(question.id);

        if (question.dependsOnQuestions.length) {
          for (const dependency of question.dependsOnQuestions) {
            const parentQuestion = flatQuestions[dependency.questionId];
            if (parentQuestion) {
              parentQuestion.dependentQuestionsIds.push(question.id);
            } else {
              flatQuestions[dependency.questionId].dependentQuestionsIds = [question.id];
            }
          }
        }
      }

      flatCategories[category.id] = {
        ...flatCategories[category.id],
        ...category,
        questionsIds,
        ...(category.subCategories ? { categoriesIds: category.subCategories.map((c) => c.id) } : {}),
        subCategories: undefined,
        questions: undefined,
      };

      if (category.parentId) {
        if (flatCategories[category.parentId]) {
          flatCategories[category.parentId].questionsIds.push(...questionsIds);
        } else {
          flatCategories[category.parentId] = {
            questionsIds,
          } as Category;
        }
      }

      if (category.subCategories) {
        processCategories(category.subCategories);
      }
    }
  };

  processCategories(data.categories);

  return { categories: flatCategories, questions: flatQuestions, structure: reorganizeStructure(data.categories) };
};

/* TODO: this should be fixed in data in question builder */
export const matchAnswer = (answer: boolean, actualAnswer?: string) => {
  return (answer === true && actualAnswer === 'yes') || (answer === false && actualAnswer === 'no');
};

export const meetsDependencies = (dependencies: Array<Dependency>, questions: Record<string, Question>): boolean => {
  return dependencies.every((d) => questions[d.questionId] && matchAnswer(d.answer, questions[d.questionId].answer));
};

export const getOrderedQuestionsIds = (questions?: Record<string, Question>): Array<string> => {
  const sortedQuestions = questions && Object.values(questions).sort((q1, q2) => q1.orderNumber - q2.orderNumber);

  if (!sortedQuestions) {
    return [];
  }

  return sortedQuestions.reduce((relevant, q) => {
    if (q.dependsOnQuestions?.length && !meetsDependencies(q.dependsOnQuestions, questions)) {
      return relevant;
    }
    return [...relevant, q.id];
  }, [] as Array<string>);
};

export const getQuestionByNumber = (number: number, questions?: Record<string, Question>) => {
  return questions && Object.values(questions).find((q) => q.orderNumber === number);
};

export const isQuestionAnswered = (answer: string = ''): boolean => {
  return ['yes', 'no'].includes(answer.toLocaleLowerCase());
};

export const areQuestionsAnswered = (ids: string[], questions: Record<string, Question>): boolean => {
  return ids.every((id) => isQuestionAnswered(questions[id].answer));
};

export const matchAccountingSuggestionAnswer = (answer?: string, triggerAnswer?: string | null) => {
  return (
    (triggerAnswer?.toLocaleLowerCase() === 'true' && answer === 'yes') ||
    (triggerAnswer?.toLocaleLowerCase() === 'false' && answer === 'no')
  );
};
