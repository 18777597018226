import { client } from 'api/config';
//TODO: basicCompanyInformationQuery is imported from another route. We should find a better solution
// maybe move this function up a level that it can be shared by all routes
import { basicCompanyInformationQuery } from 'routes/pages/Start/pages/BasicInformation/loader';
import { Representative } from 'routes/pages/Start/pages/BasicInformation/types';
import { Text } from '@simplifiers/ui/Typography';
import { useQuery } from '@tanstack/react-query';
import styles from './ManagementReport.module.css';

type IntroductionTextProps = {
  companyName: string;
  financialYear?: string;
  founder?: Representative;
};

export const IntroductionText = ({ companyName, financialYear, founder }: IntroductionTextProps) => {
  const { data: basicInformation, isLoading } = useQuery(basicCompanyInformationQuery(client.id));
  const ceoText = founder ? 'och verkställande direktören' : '';

  const text = (
    <div className={styles.boldText}>
      Styrelsen {ceoText} för {companyName} avger följande årsredovisning för verksamhetsåret {financialYear}.
      <div>Årsredovisningen är upprättade i svenska kronor (SEK).</div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!basicInformation) {
    return null;
  }

  return <Text className={styles.spacerXL}>{text}</Text>;
};
