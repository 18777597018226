import { Title } from '@simplifiers/ui/Typography';
import styles from './PageTitle.module.css';

type PageTitleProps = {
  children: React.ReactElement | string;
};

export const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <Title large className={styles.pageTitle}>
      {children}
    </Title>
  );
};
