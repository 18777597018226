import { Menu } from '@simplifiers/ui/Menu';
import { SubMenuItem } from './SubMenuItem';
import { SubMenuItemData } from './types';

type SubMenuProps = {
  parts?: Array<SubMenuItemData>;
  parentId: string;
};

export const SubMenu = ({ parts, parentId }: SubMenuProps) => {
  if (!parts?.length) {
    return null;
  }

  return (
    <Menu>
      {parts.map((item) => {
        const fullId = `${parentId}/${item.id}`;
        return <SubMenuItem key={fullId} item={item} fullId={fullId} />;
      })}
    </Menu>
  );
};
