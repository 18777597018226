import { useParams } from 'react-router-dom';
import { Grid } from '@simplifiers/ui/Layout/Grid';
import { isQuestionAnswered } from '../../data';
import { Question, QuestionsForm } from '../../types';
import { ProgressItem } from './ProgressItem';

type ProgressProps = {
  form?: QuestionsForm;
  orderedQuestionsIds: Array<string>;
};

const getAnswersAmount = (questions: Record<string, Question>, questionsIds: Array<string>): number => {
  return questionsIds.filter((id) => isQuestionAnswered(questions[id]?.answer)).length;
};

export const Progress = ({ form, orderedQuestionsIds }: ProgressProps) => {
  const { questionId } = useParams();

  if (!form?.structure || !orderedQuestionsIds.length) {
    return null;
  }

  return (
    <Grid columns={{ base: form.structure.length }} colGap="md">
      {form.structure.map((c) => {
        const category = form.categories[c.id];
        if (!category) {
          return null;
        }

        const questionsIds = category.questionsIds.filter((id) => orderedQuestionsIds.includes(id));

        return (
          <ProgressItem
            isActive={questionId ? questionsIds.includes(questionId) : false}
            key={category.id}
            title={category.name}
            answers={getAnswersAmount(form.questions, questionsIds)}
            total={questionsIds.length}
          />
        );
      })}
    </Grid>
  );
};
