interface DefinitionCardProps {
  term: string;
  definition: string;
  className?: string;
}

export const DefinitionCard = ({ term, definition, className }: DefinitionCardProps) => {
  return (
    <div className={className}>
      <dt>{term}</dt>
      <dd> {definition}</dd>
    </div>
  );
};
