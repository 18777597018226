import { Container as FContainer } from '@fnox/eternal-smooth-ui';

type PaddingVertical = typeof FContainer extends (props: { paddingVertical: infer T }) => unknown ? T : never;
type PaddingHorizontal = typeof FContainer extends (props: { paddingHorizontal: infer T }) => unknown ? T : never;
type Padding = typeof FContainer extends (props: { padding: infer T }) => unknown ? T : never;

type Width = typeof FContainer extends (props: { minWidth: infer T }) => unknown ? T : never;

type ContainerProps = {
  children: React.ReactNode;
  padding?: Padding;
  paddingVertical?: PaddingVertical;
  paddingHorizontal?: PaddingHorizontal;
  centerHorizontally?: boolean;
  centerVertically?: boolean;
  minWidth?: Width;
  maxWidth?: Width;
  className?: string;
};

export const Container = (props: ContainerProps) => {
  const {
    children,
    padding,
    paddingHorizontal,
    paddingVertical,
    centerHorizontally,
    centerVertically,
    minWidth,
    maxWidth,
    className,
  } = props;

  return (
    <FContainer
      as="div"
      padding={padding}
      paddingVertical={paddingVertical}
      paddingHorizontal={paddingHorizontal}
      centerHorizontally={centerHorizontally}
      centerVertically={centerVertically}
      minWidth={minWidth}
      maxWidth={maxWidth}
      className={className}
    >
      {children}
    </FContainer>
  );
};
