import { QueryClient } from '@tanstack/react-query';
import { Root } from './Root';
import { ErrorPage } from './pages/Error';
import { reportRoutes } from './pages/Report/route';
import { startRoutes } from './pages/Start/route';

export const rootRoutes = (queryClient: QueryClient) => {
  return {
    path: '/',
    element: <Root />, //header
    errorElement: <ErrorPage />,
    children: [startRoutes, reportRoutes(queryClient)],
  };
};
