import { AwesomeIconButton } from '@fnox/eternal-smooth-ui';
import { IconNameType, IconSize } from '@simplifiers/ui/Icon/types';

type IconButtonProps = {
  name: IconNameType;
  ariaLabel: string;
  size?: IconSize;
  onClick: () => void;
};

export const IconButton = (props: IconButtonProps) => {
  const { name, ariaLabel, size, onClick } = props;

  return <AwesomeIconButton name={name} aria-label={ariaLabel} size={size} onClick={onClick} />;
};
