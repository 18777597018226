import { Navigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { AnnualReport } from './AnnualReport';
import { documentRoutes } from './pages/Document/route';
import { paymentAndVouchersRoutes } from './pages/PaymentAndVouchers/route';

export const annualReportRoutes = (queryClient: QueryClient) => {
  return {
    path: 'annual-report',
    element: <AnnualReport />,
    errorElement: <span>Something went wrong when loading annual-report</span>,
    children: [
      { index: true, element: <Navigate to="document" replace /> },
      { path: '*', element: <Navigate to="document" replace /> },
      documentRoutes(queryClient),
      paymentAndVouchersRoutes,
    ],
  };
};
