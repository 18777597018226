import { NavLink } from 'react-router-dom';
import { Icon } from '@simplifiers/ui/Icon';
import cx from 'classnames';
import styles from './Menu.module.css';

export type MenuItemProps = {
  to: string;
  title: string;
  active: boolean;
  completed?: boolean;
  expanded: boolean;
  onToggle?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
};

export const MenuItem = ({ to, title, active, completed, expanded, onToggle, children }: MenuItemProps) => {
  return (
    <li>
      <NavLink to={to} className={cx(styles.link, { [styles.active]: active })}>
        {children && (
          <button onClick={onToggle} className={styles.toggleButton}>
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} className={styles.icon} size="2xs" />
          </button>
        )}
        <Icon
          size="2xs"
          className={styles.statusIcon}
          name={completed ? 'circle-check' : 'circle'}
          color={completed ? 'var(--action-color)' : undefined}
          type={completed ? 'solid' : 'light'}
        />
        <span className={styles.name}>{title}</span>
      </NavLink>
      {expanded && children}
    </li>
  );
};
