import { Text as FText } from '@fnox/eternal-smooth-ui';

export type TextColors = typeof FText extends (props: { color: infer T }) => unknown ? T : never;

type TextProps = {
  className?: string;
  color?: TextColors;
  children: React.ReactNode;
  as?: 'div' | 'span' | 'p';
};

export const Text = ({ className, color, children, as = 'span' }: TextProps) => {
  return (
    <FText className={className} color={color} as={as}>
      {children}
    </FText>
  );
};
