import { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './Dropdown.module.css';

export interface DropdownOptionProps<T> {
  label: string;
  value: T;
  focused?: boolean;
  selected?: boolean;
  onClick: (value: T) => void;
}

export const DropdownOption = <T extends string | number>({
  label,
  value,
  focused,
  selected,
  onClick,
}: DropdownOptionProps<T>) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused]);

  return (
    <li
      onClick={() => onClick(value)}
      className={cx(styles.dropdownOption, { [styles.selected]: selected })}
      role="option"
      aria-selected={selected}
      tabIndex={-1}
      ref={ref}
    >
      {label}
    </li>
  );
};
