import React from 'react';
import { TextField } from '@fnox/eternal-smooth-ui';

export type InputProps = {
  name: string;
  label?: string;
  inputMode?: 'search' | 'numeric' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'decimal';
  disabled?: boolean;
  multiline?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  size?: 'small' | 'default';
  className?: string;
  value: string;
};

export const Input = React.forwardRef(
  (
    {
      label = '',
      inputMode = 'text',
      multiline = false,
      autoFocus = false,
      readOnly,
      value = '',
      ...props
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    return (
      <TextField
        title={label}
        type={multiline ? 'textarea' : 'text'}
        inputMode={inputMode}
        inputRef={ref}
        autoFocus={autoFocus}
        readOnly={readOnly}
        value={value}
        {...props}
      />
    );
  }
);
