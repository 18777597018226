import React from 'react';
import styles from './GridTable.module.css';

type GridTableProps = {
  children: React.ReactNode;
};

export const GridTable = (props: GridTableProps) => {
  const { children } = props;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>{children}</table>
    </div>
  );
};
