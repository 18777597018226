export const removeExtraCharactersFromCurrencyInput = (value: number): string => {
  return value
    .toString()
    .replace(/^\+/, '')
    .replace(/[^\d.,]/g, '');
};


/**
 * Function to format value with dot as separator in order to correctly parse float.
 */
export const withDotSeparator = (value: string) => {
  return value.replace(',', '.').replaceAll(' ', '');
};
